/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {getWebInstrumentations, initializeFaro} from '@grafana/faro-react';
import {TracingInstrumentation} from '@grafana/faro-web-tracing';

initializeFaro({
  url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/3dfc5b8216315847460cb2552216e4e3',
  app: {
    name: 'INDGO-AUTH-LOGIN-UI',
    version: '1.0.0',
    // eslint-disable-next-line no-undef
    environment: process.env.ENV,
  },

  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // Tracing package to get end-to-end visibility for HTTP requests.
    new TracingInstrumentation(),
  ],
});

import App from './App';
// import store from './store/store';
// import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  // <Provider store={store}>
  <Router>
    <App />
  </Router>,
  // </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
